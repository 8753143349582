@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap);
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #333 !important;
}
a:hover {
  text-decoration: none !important;
}

.card {
  width: 14rem;
  height: 14rem;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin: 20px;
  border-radius: 30px !important;
}

.card:hover {
  background-color: rgba(238, 238, 238, 0.3);
}

.img-frame {
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
}
.img-frame:hover {
  transform: scale(1.15);
  transition: transform 0.3s ease;
}
.im1 {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-thumbnail {
  border: none !important;
}
.img-frame img {
  width: 100% !important;
  height: 100% !important;
}

h3 {
  padding: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1.13rem !important;
  font-weight: 800 !important;
}

.title {
  text-transform: capitalize;
  transition: transform 0.3s ease;
}

.title:hover {
  transform: scale(0.9);
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
  transition: transform 0.3s ease;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

/*
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
*/

.text-muted {
  text-transform: capitalize;
  text-align: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: #eee;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 9999;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.butn {
  position: relative;
  font-weight: 800 !important;
  outline: none;
  border: 3px solid #212529;
  padding: 8px 16px;
  border-radius: 0;
  font-size: 18px;
  color: #212529;
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  top: 20px;
  left: 20px;
  transition: all 0.4s ease;
}

.butn:hover {
  background-color: #212529;
  color: #eee;
  transition: all 0.4s ease;
  outline: none;
}

.butn:focus,
.butn:active {
  outline: none;
}

.MuiSvgIcon-root {
  color: #f44336;
}

.col-color {
  color: #f44336;
}
.section h1 {
  font-size: 100px;
  word-spacing: 0.5rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  -webkit-animation: pop 0.5s ease;
          animation: pop 0.5s ease;
}

.text-infos {
  background-color: #f44336;
  padding: 16px;
}

.text-infos h5 {
  border-radius: 0;
  color: #eee;
  padding: 10px 20px;
}

.order-list {
  color: #eee;
}

@-webkit-keyframes pop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  20% {
    opacity: 0.5;
    transform: translateY(-80%);
  }
  40% {
    opacity: 1;
    transform: translateY(-40%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  65% {
    opacity: 1;
    transform: translateY(50%);
  }
  80% {
    opacity: 1;
    transform: translateY(0%);
  }
  90% {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  20% {
    opacity: 0.5;
    transform: translateY(-80%);
  }
  40% {
    opacity: 1;
    transform: translateY(-40%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  65% {
    opacity: 1;
    transform: translateY(50%);
  }
  80% {
    opacity: 1;
    transform: translateY(0%);
  }
  90% {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

