.nav-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

/*
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
*/

.text-muted {
  text-transform: capitalize;
  text-align: center;
}
