a {
  color: #333 !important;
}
a:hover {
  text-decoration: none !important;
}

.card {
  width: 14rem;
  height: 14rem;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin: 20px;
  border-radius: 30px !important;
}

.card:hover {
  background-color: rgba(238, 238, 238, 0.3);
}

.img-frame {
  width: 100px;
  height: 100px;
  transition: transform 0.3s ease;
}
.img-frame:hover {
  transform: scale(1.15);
  transition: transform 0.3s ease;
}
.im1 {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-thumbnail {
  border: none !important;
}
.img-frame img {
  width: 100% !important;
  height: 100% !important;
}

h3 {
  padding: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1.13rem !important;
  font-weight: 800 !important;
}

.title {
  text-transform: capitalize;
  transition: transform 0.3s ease;
}

.title:hover {
  transform: scale(0.9);
  filter: brightness(0.5);
  transition: transform 0.3s ease;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
