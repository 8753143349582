.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: #eee;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 9999;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.butn {
  position: relative;
  font-weight: 800 !important;
  outline: none;
  border: 3px solid #212529;
  padding: 8px 16px;
  border-radius: 0;
  font-size: 18px;
  color: #212529;
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  top: 20px;
  left: 20px;
  transition: all 0.4s ease;
}

.butn:hover {
  background-color: #212529;
  color: #eee;
  transition: all 0.4s ease;
  outline: none;
}

.butn:focus,
.butn:active {
  outline: none;
}

.MuiSvgIcon-root {
  color: #f44336;
}

.col-color {
  color: #f44336;
}
.section h1 {
  font-size: 100px;
  word-spacing: 0.5rem;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  animation: pop 0.5s ease;
}

.text-infos {
  background-color: #f44336;
  padding: 16px;
}

.text-infos h5 {
  border-radius: 0;
  color: #eee;
  padding: 10px 20px;
}

.order-list {
  color: #eee;
}

@keyframes pop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  20% {
    opacity: 0.5;
    transform: translateY(-80%);
  }
  40% {
    opacity: 1;
    transform: translateY(-40%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  65% {
    opacity: 1;
    transform: translateY(50%);
  }
  80% {
    opacity: 1;
    transform: translateY(0%);
  }
  90% {
    opacity: 1;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
